<template>
  <div>
    <v-alert
      v-model="alert"
      :color="getAlertColor(alert)"
      border="left"
      elevation="2"
      colored-border
      style="margin:auto; width: 640px; z-index:10010;"
    >
      <div class="row p-0 m-0 alert-content">
        <div class="col-1 p-0 m-0 pl-4" style="align-self: center;">
          <img
            :style="getIconStyle(alert)"
            v-if="alert==='Success'"
            :src="getImage(alert)"
          />
          <img 
            :style="getIconStyle(alert)"
            v-if="alert==='Warning'"
            :src="getImage(alert)"
          />
          <img 
            :style="getIconStyle(alert)"
            v-if="alert==='Failure'"
            :src="getImage(alert)"
          />
        </div>
        <div class="col-10 p-0 m-0 pl-5">
          <p class="alert-key m-0">{{ alert }}</p>
          <label class="alert-msg m-0">{{ alertMsg }}</label>
        </div>
        <div class="col-1 p-0 m-auto close-icon" @click="closeAlert">
          <img src="../assets/alert-msg/close.svg" />
        </div>
      </div>
    </v-alert>
    <div id="overlay" style="diplay:block"></div>
  </div>
</template>

<script>
export default {
  props: ["alert", "alertMsg"],

  data() {
    return {};
  },
  methods: {
    closeAlert() {
      this.$emit("closeAlert");
    },
    getIconStyle(alert) {
      switch (alert) {
        case "Success":
          return "background-color: #47d764;border-radius: 100%; padding: 2px; height:50px; width:50px";
        case "Warning":
          return "background-color: #ff0808;border-radius: 100%; padding: 8px; height:50px; width:50px";
        case "Failure":
          return "background-color: #fff;border-radius: 100%; padding: 2px; height:50px; width:50px";
        default:
          return "background-color: #47d764;border-radius: 100%; padding: 2px; height:50px; width:50px";
      }
    },
     getImage(image) {
      var images = require.context("../assets/alert-msg/", false, /\.svg$/);
      return images("./" + image + ".svg");
    },
    getAlertColor(alert) {
      switch (alert) {
        case "Success":
          return "#47d764";
        case "Warning":
          return "#ff0808";
        case "Failure":
          return "#ff0808";
        default:
          return "#47d764";
      }
    }
  }
};
</script>

<style scoped>
#overlay {
  position: fixed; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 10000; 
  cursor: pointer; 
  -webkit-backdrop-filter: blur(10px);
  /* backdrop-filter: blur(10px); */
}

.alert-content {
  display: flex;
  flex-direction: row;
}
.alert-key {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #191d24;
}
.alert-msg {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #898b91;
}
.close-icon {
  cursor: pointer;
}
</style>